import { useAppSelector, useAppDispatch } from "store";
import { FiMenu } from "react-icons/fi";
import Dropdown5 from "components/navbar-1/dropdown-5";
import { setConfig } from "slices/config";
import Space from "components/Space";
import { FaCashRegister, FaGlassMartini, FaPhotoVideo } from "react-icons/fa";
import { useRouter } from "next/router";

const Navbar: React.FC = () => {
  const router = useRouter()
  const config = useAppSelector((state) => state.config);
  const { rightSidebar, collapsed } = config;
  const dispatch = useAppDispatch();

  let permissions: string[] = []
  if (typeof window !== 'undefined') {
    permissions = JSON.parse(localStorage?.getItem('permissions') as string);
  }

  return (
    <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-white border-b border-gray-100 dark:border-gray-800">
      <div className="flex items-center justify-start w-full py-3 pr-5">
        <button
          onClick={() =>
            dispatch(
              setConfig({
                collapsed: !collapsed,
              })
            )
          }
          className="mx-4">
          <FiMenu size={20} />
        </button>
        {
          permissions?.includes('POS_PAY_PERMISSION') &&
          <button
            onClick={() => router.push('/pos')}
            className="flex flex-row items-center justify-center px-4 py-2 text-xs font-bold text-blue-500 uppercase bg-transparent rounded-lg shadow border border-blue-500 hover:bg-blue-50 hover:text-blue-800 dark:hover:bg-gray-800 dark:hover:text-blue-800">
            <FaCashRegister className="stroke-current" />
            <span className="ml-2 text-xs">POS</span>
          </button>
        }
        {
          permissions?.includes('CAFE_PERMISSION') &&
          <button
            onClick={() => router.push('/cafe/sales')}
            className="flex flex-row items-center justify-center ml-2 px-4 py-2 text-xs font-bold text-red-500 uppercase bg-transparent rounded-lg shadow border border-red-500 hover:bg-red-50 hover:text-red-800 dark:hover:bg-gray-800 dark:hover:text-red-800">
            <FaGlassMartini className="stroke-current" />
            <span className="ml-2 text-xs">Cafe</span>
          </button>
        }
        {/* {
          permissions?.includes('POS_PAY_PERMISSION') &&
          <button
            onClick={() => router.push('/photocopy/sales')}
            className="flex flex-row items-center justify-center ml-2 px-4 py-2 text-xs font-bold text-emerald-500 uppercase bg-transparent rounded-lg shadow border border-emerald-500 hover:bg-emerald-50 hover:text-emerald-800 dark:hover:bg-gray-800 dark:hover:text-emerald-800">
            <FaPhotoVideo className="stroke-current" />
            <span className="ml-2 text-xs">Photocopy</span>
          </button>
        } */}
        <span className="ml-auto"></span>
        <Space>

          <Dropdown5 />
        </Space>
      </div>
    </div>
  );
};

export default Navbar;
