import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEmployee } from "types/employee";
import { IRole } from "types/role";
import { IOfficeBranch, TokenInfo } from "types/user";

// Define a type for the slice state

interface UserStore {
  id: string,
  username: string,
  tokenInfo: TokenInfo | null,
  officeBranch: IOfficeBranch | null,
  roles: IRole[]
  employee: IEmployee | null
};

// Define the initial state using that type
const initialState: UserStore = {
  id: "",
  username: "",
  employee: null,
  tokenInfo: null,
  officeBranch: null,
  roles: []
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<UserStore>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
