import Link from "next/link";
import Dropdown5 from "components/navbar-1/dropdown-5";
import { FiHome } from "react-icons/fi";

function Navbar() {
  return (
    <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-white border-b border-gray-100 dark:border-gray-800">
      <div className="flex justify-between items-center w-full py-3 pl-5">
        <div className="logo truncate text-blue-500 text-base tracking-wider uppercase font-bold">
          <Link as="a" href="/">
            <div className="flex flex-row items-center">
              <FiHome />
              <Link href="/" className="pt-1">Home</Link>
            </div>
          </Link>

        </div>

        <div>
          <Dropdown5 />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
