import type { AppProps } from "next/app";
import Head from "next/head";
import { Provider } from "react-redux";
import Layout from "layouts";
import store from "store";
import Router, { useRouter } from "next/router";
import NProgress from "nprogress";
import "css/tailwind.css";
import "css/main.css";
import "css/layouts/layout-1.css";
import "css/layouts/e-commerce.css";
import "css/animate.css";
import "css/components/left-sidebar-1/styles-lg.css";
import "css/components/left-sidebar-1/styles-sm.css";
import "css/components/nprogress.css";
import "css/components/recharts.css";
import "css/components/steps.css";
import "css/components/left-sidebar-3.css";
import ModalRes from "components/modals/modal-response";
import { useEffect, useState } from "react";
import { apiService } from "services/private";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { IdleTimerProvider } from "react-idle-timer";
import { IDLE_TIMEOUT } from "config/app.config";
import { getAuthorizationHeader } from "utils/auth";
import Cookies from "js-cookie";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());


function App({ Component, pageProps }: AppProps): React.ReactElement {
  const [showError, setErrorModal] = useState<boolean>(false)
  const errorsServer = apiService.useResError()

  const router = useRouter();

  useEffect(() => {
    if (errorsServer) {
      console.log('ERROR : ', errorsServer);
      if (errorsServer?.status > 300) {
        setErrorModal(true)
      }
    }
  }, [errorsServer])

  const logout = async () => {
    console.log('onIdle');

    if (window.location.pathname !== '/login') {
      if (getAuthorizationHeader()) {
        await apiService.invokeAPI('LOGOUT', {}, 'post')
      }
      localStorage.clear()
      Cookies.remove('currentUser')
      router.push('logout')
    }
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Amsatasik</title>
      </Head>
      <Provider store={store}>
        <IdleTimerProvider
          timeout={IDLE_TIMEOUT * 60000}
          onIdle={logout}
        >
          <Layout>
            <Component {...pageProps} />
            <ToastContainer />
          </Layout>
          <ModalRes
            show={showError}
            type="FAILED"
            message={errorsServer?.message}
            onClose={async () => {
              setErrorModal(false)
              if (errorsServer.status == 401 || errorsServer.status === 503) {
                logout()
              }
            }} />
        </IdleTimerProvider>
      </Provider>
    </>
  );
}
export default App;
