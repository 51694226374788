import Cookies from "js-cookie";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { FiMail, FiStar, FiUser, FiLogIn } from "react-icons/fi";
import { apiService } from "services/private";

export type ItemProps = {
  url: string;
  icon: React.ReactNode;
  iconColor?: string;
  name: string;
  badge?: {
    number: any;
    color: string;
  };
};

const pageIndex = 1
const pageSize = 1

const AccountLinks: React.FC = () => {
  const router = useRouter();

  const permissions: string[] = JSON.parse(localStorage.getItem('permissions') as string);

  let items: ItemProps[] = [
    // {
    //   url: "/loan",
    //   icon: <FiMail size={18} className="stroke-current" />,
    //   name: "Inbox",
    //   badge: {
    //     number: 0,
    //     color: "bg-red-500 text-white",
    //   },
    // },
    // {
    //   url: "/",
    //   icon: <FiStar size={18} className="stroke-current" />,
    //   iconColor: "default",
    //   name: "Messages",
    //   badge: {
    //     number: 3,
    //     color: "bg-blue-500 text-white",
    //   },
    // },
    // {
    //   url: "/",
    //   icon: <FiUser size={18} className="stroke-current" />,
    //   name: "Profile",
    // },
    {
      url: "/logout",
      icon: <FiLogIn size={18} className="stroke-current" />,
      name: "Logout",
    },
  ];

  if (permissions?.includes("APV_PERMISSION")) {
    items = [
      {
        url: "/loan",
        icon: <FiMail size={18} className="stroke-current" />,
        name: "Inbox",
        badge: {
          number: 0,
          color: "bg-red-500 text-white",
        }
      },
      ...items
    ];
  }

  const [approvalTotal, setApprovalTotal] = useState(0)

  useEffect(() => {
    getInbox()
  }, [])

  const getInbox = async () => {
    if (permissions?.includes("APV_PERMISSION")) {
      const res = await apiService.invokeAPI("LOAN_APPROVAL_TASK", {
        pageIndex,
        pageSize
      })

      if (res.status == 200) {
        setApprovalTotal(res.data.totalItem)
      }
    }
  }

  const Inbox = useCallback(
    (props: any) => {
      if (approvalTotal < 1) return null;

      return (
        <span
          className={`uppercase font-bold inline-flex text-center p-0 leading-none text-xs h-4 w-4 inline-flex items-center justify-center rounded-full ${props} ml-auto`}>
          {approvalTotal}
        </span>
      )
    }, [approvalTotal],
  )



  return (
    <div className="flex flex-col w-full">
      <ul className="list-none">
        {items.map((item, i) => (
          <li key={i}>
            <a
              onClick={async () => {
                if (item.url === '/logout') {
                  await apiService.invokeAPI('LOGOUT', {}, 'post')
                  localStorage.clear()
                  Cookies.remove('currentUser')
                }
                router.push(item.url)
              }}
              className="flex flex-row items-center justify-start w-full h-10 px-2 text-gray-900 bg-white dark:bg-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
              {item.icon}
              <span className="mx-2">{item.name}</span>
              {item.badge && (
                Inbox(item.badge.color)
              )}
            </a>
            {/* </Link> */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AccountLinks;
