import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosResponse } from "axios";
import { getAuthorizationHeader } from "../../utils/auth";
import { END_POINT, END_POINT_KEY } from "./api-config";
import { IErrorServer, IHTTPMethod, IListQueryParam } from "types/global";
import { useEffect, useState } from "react";
import errorMsg from "json/error-message.json";
import moment from "moment";
import { environment } from "environments/environment.prod";

export class ApiService {
  protected errorBag: any = errorMsg;
  protected readonly instance: AxiosInstance;
  public constructor(url: string) {
    this.instance = axios.create({
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      timeout: 20000,
      timeoutErrorMessage: "Request Time Out!"
    });
  }

  private prefixNextParam = (value: string) => value == '' ? '?' : '&'

  invokeAPI(API: END_POINT_KEY, data: IListQueryParam | null, method?: IHTTPMethod): AxiosPromise
  invokeAPI(API: END_POINT_KEY, data: any, method?: IHTTPMethod): AxiosPromise
  invokeAPI(
    API: END_POINT_KEY,
    data: IListQueryParam,
    method: IHTTPMethod = 'get'
  ): AxiosPromise {
    this.instance.defaults.headers.common['Authorization'] = getAuthorizationHeader()

    let url = `api/${END_POINT.get(API)}`
    const {
      paramId
    } = data;
    if (paramId) {
      url = `${url}/${paramId}`
    }
    if (method === 'get') {
      if (data) {
        let PARAMS = this.mapParams(data)

        url = url + PARAMS
      }
      if (data?.output == 'xlsx') {
        this.instance.defaults.timeout = 100000
        this.instance.defaults.headers.common['Content-Disposition'] = "attachment; filename=Test.xlsx"
        this.instance.defaults.headers.common['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        return this.instance.get(url, {
          responseType: 'arraybuffer'
        });
      }
      return this.instance.get(url);
    } else {
      if (['PAYMENT_AUTO', 'MEMBER_UPLOAD'].includes(API)) {
        console.log('url : ', url);
        console.log('bodyFormData : ', data);

        let bodyFormData = new FormData();
        bodyFormData.append('file', data.file[0])
        return this.instance.post(url, bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
      } else {
        return this.instance[method](url, data);
      }
    }
  }

  private mapParams(data: any) {
    let PARAMS = ''

    if (!data) return ''

    for (let [_, key] of Object.keys(data).entries()) {
      if (
        data[key] !== ''
        && key !== 'paramId'
        && data[key] !== undefined
        && data[key] !== null
        || (typeof data[key] === 'number' && data[key] > -1)) {
        PARAMS = `${PARAMS + this.prefixNextParam(PARAMS)}${key}=${data[key]}`
      }
    }

    return PARAMS
  }

  public fetchFile(endpoint: string) {
    return `${process.env.BASE_URL}/${endpoint}`
  }

  async downloadPDF(API: END_POINT_KEY, data: any, fileName: string): Promise<any> {
    const baseUrl = process.env.BASE_URL ?? environment.baseUrl
    let url = baseUrl + `/api/${END_POINT.get(API)}`
    const {
      paramId
    } = data;
    if (paramId) {
      url = `${url}/${paramId}`
    }
    let PARAMS = this.mapParams({ ...data, output: 'pdf' })

    url = url + PARAMS

    return await fetch(url, {
      headers: {
        'Authorization': getAuthorizationHeader()
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        const objectURL = URL.createObjectURL(blob)
        this.openLink(objectURL, fileName, '.pdf')
        return {
          status: "SUCCESS",
          message: "Berhasil mengunduh data!"
        }
      }).catch((e) => {
        return {
          status: "FAILED",
          message: "Gagal mengunduh data!"
        }
      });
  }

  openLink(objectURL: any, fileName: string, type: string) {
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.href = objectURL;
    downloadLink.download = fileName + moment().format('DD-MM-YYYY-HH-mm') + type;
    downloadLink.style.display = "none";
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  useAxiosLoader = () => {
    const [counter, setCounter] = useState(0);

    useEffect(() => {
      const inc = (mod: any) => setCounter(c => c + mod);

      const handleRequest = (config: any) => (inc(1), config);
      const handleResponse = (response: any) => (inc(-1), response);
      const handleError = (error: any) => (inc(-1), Promise.reject(error));

      // add request interceptors
      const reqInterceptor = this.instance.interceptors.request.use(handleRequest, handleError);
      // add response interceptors
      const resInterceptor = this.instance.interceptors.response.use(handleResponse, handleError);
      return () => {
        // remove all intercepts when done
        this.instance.interceptors.request.eject(reqInterceptor);
        this.instance.interceptors.response.eject(resInterceptor);
      };
    }, []);

    return counter > 0;
  };

  useResError = () => {
    const [error, setError] = useState<any>();

    useEffect(() => {
      const handleError = (error: any) => {
        if (error?.code == 'ECONNABORTED') {
          let newError = {
            message: ['Request Timeout. Mohon cek jaringan anda!'],
            status: 500
          }
          setError(newError)
          return newError
        }
        let message: any[] = []
        const errorData: IErrorServer = error?.response?.data

        const statusCode = !errorData ? error.response.status : errorData.status

        if (errorData) {
          if (errorData.errors) {
            message = Object.values(errorData.errors)
          }
          else if (errorData.title) {
            let text = this.errorBag[errorData.title]
            if (text) {
              message.push(text)
            }
          }
          if (!message.length) {
            if (errorData.detail && (typeof errorData.detail == 'string')) {
              message.push(errorData.detail)
            }
          }
        }

        if (!message.length) {
          message.push(this.getErrorDefault(statusCode))
        }

        const newError = {
          message: message,
          status: statusCode
        }
        setError(newError)
        return newError
      }

      const resInterceptor = this.instance.interceptors.response.use((response) => {
        return response
      }, handleError);
      return () => {
        this.instance.interceptors.response.eject(resInterceptor);
      };
    }, []);

    return error;
  };

  getErrorDefault(status: number) {
    switch (status) {
      case 400:
        return status + " : Data yang dikirim tidak valid!"
      case 401:
        return status + " : Maaf, anda tidak terautentikasi, silahkan login ulang!"
      case 403:
        return status + " : Maaf, anda tidak memiliki akses!"
      case 404:
        return status + " : Maaf, Data tidak ditemukan!"
      case 413:
        return status + " : Maaf, Request yang dikirim terlalu besar!"
      default:
        return "Terjadi kesalahan, mohon tunggu beberapa saat lagi!"
    }
  }
}