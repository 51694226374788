import AccountLinks from "components/navbar-1/account-links";
import clsx from "clsx";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useUserLoggedIn } from "utils/auth";

const Dropdown: React.FC = () => {
  const { user } = useUserLoggedIn();

  const greetRef:any = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if(greetRef != null) {
        greetRef.current.innerText = user?.username ?? ""
      }
    }, 100);
  }, [])


  return (
    <Menu as="div" className="relative hidden lg:inline-block text-left mr-5 mt-1">
      <div>
        <Menu.Button className="focus:outline-none flex justify-center space-x-2">
          <div className="py-1">
            Selamat Datang, <span className="text-blue-800 font-bold" ref={greetRef}></span>!
          </div>
          <div className="relative w-8 h-8">
            {/* <Image
              src={profilePic}
              alt="avatar"
              className="rounded-full shadow-lg"
            /> */}
            <FaUserCircle size={25} className="text-blue-500 stroke-current justify-center" />
            {/* <span className="absolute inline-flex items-center justify-center w-4 h-4 p-0 font-bold leading-none text-center text-white bg-red-500 rounded-full top-[-6px] right-[-6px] text-xs ring-2 ring-white">
              2
            </span> */}
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items
          className={clsx(
            "absolute w-[192px] bg-white dark:bg-gray-800 shadow-lg divide-y divide-gray-100 dark:divide-gray-700 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none",
            "right-0 origin-top-right"
          )}>
          <AccountLinks />
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default Dropdown;
