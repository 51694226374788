import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Define a type for the slice state

interface SavingMemberStore {
  memberNo: string,
  memberName: string
};

// Define the initial state using that type
const initialState: SavingMemberStore = {
  memberNo: "",
  memberName: ""
};

export const savingMemberSlice = createSlice({
  name: "saving_member",
  initialState,
  reducers: {
    setSavingMember: (state, action: PayloadAction<Partial<SavingMemberStore>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setSavingMember } = savingMemberSlice.actions;

export default savingMemberSlice.reducer;
