import classNames from 'classnames'

type Direction = 'vertical' | 'horizontal'

type SpaceProps = {
  children: React.ReactNode;
  wrap?: boolean,
  direction?: Direction,
  className?: string
}

const Space: React.FC<SpaceProps> = ({children, wrap, direction, className}) => {

  return <div className={classNames("flex gap-2 items-center", {"flex-wrap" : wrap, "flex-col": direction === 'vertical'}, className)}>
      {children}
  </div>
}

Space.defaultProps = {
  wrap: false,
  direction: 'horizontal'
}

export default Space