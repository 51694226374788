import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useRef, useCallback } from "react";
import { CheckIcon } from "@heroicons/react/outline";
import { ExclamationIcon } from "@heroicons/react/solid";

type Props = {
  show: boolean;
  message: string | string[];
  type: "FAILED" | "SUCCESS";
  onClose(): void;
}
const ModalRes: React.FC<Props> = ({
  show,
  message,
  type,
  onClose
}) => {
  const [isOpen, setOpen] = useState<boolean>(true);
  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);
  const cancelButtonRef = useRef(null);

  const IconHeader = useCallback(
    () => {
      if (type === 'FAILED') {
        return (
          <ExclamationIcon
            className="w-6 h-6 text-red-600"
            aria-hidden="true"
          />
        )
      }
      return (
        <CheckIcon
          className="w-6 h-6 text-green-600"
          aria-hidden="true"
        />
      )
    },
    [type],
  )


  return (
    <>
      {
        show &&
        <Transition appear show={true} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={closeModal}>
            <div className="flex flex-col items-center justify-center min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-900/25" />
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <div className="relative inline-block w-full max-w-md p-4 overflow-hidden text-left align-middle bg-white shadow-xl dark:bg-gray-700 dark:text-white transition-all transform rounded-2xl space-y-4">
                  <div>
                    <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                      {IconHeader()}
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium text-gray-900 dark:text-white">
                        Perhatian!
                      </Dialog.Title>
                      <div className="mt-2">
                        {
                          typeof message === 'string' ?
                            <p className="text-sm text-gray-500 dark:text-white">
                              {message}
                            </p>
                            :
                            message.map((m,i) => (
                              <p key={'p_'+i} className="text-sm text-gray-500 dark:text-white">
                                {m}
                              </p>
                            ))
                        }
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center space-x-2 mt-10">
                    <button
                      type="button"
                      className="inline-flex justify-center px-3 py-2 ml-3 text-sm font-medium text-white bg-blue-800 border border-transparent shadow-sm rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={() => onClose?.()}
                      ref={cancelButtonRef}>
                      Tutup
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      }
    </>
  );
};

export default ModalRes;
