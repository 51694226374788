import Cookies from "js-cookie";

export function getAuthorizationHeader() {
  const cookie = Cookies.get("currentUser");
  
  let token = ''
  if (cookie) {
    const user = JSON.parse(cookie);
    
    if (user) {
      token = `Bearer ${user.token}`
    }
  }
  
  return token;
}

export function useUserLoggedIn() {
  const cookie = Cookies.get("currentUser");
  let user = null;
  if (cookie) {
    user = JSON.parse(cookie);
  }

  return { user };
}