import { createSlice } from "@reduxjs/toolkit";
import { AiFillBank } from "react-icons/ai";
import { BsFillEnvelopePaperFill, BsFillStopwatchFill } from "react-icons/bs";
import { FaCalculator, FaCcMastercard, FaChartBar, FaCog, FaDatabase, FaExchangeAlt, FaFileDownload, FaFileExcel, FaHandHolding, FaHandshake, FaMoneyBillAlt, FaMoneyBillWave, FaNewspaper, FaPiggyBank, FaStar, FaStore, FaTruckPickup, FaTshirt, FaUserCog, FaUsers, FaWarehouse, FaWineGlass } from "react-icons/fa";
import { FaMoneyBillTrendUp, FaPhotoFilm } from "react-icons/fa6";
import {
  FiSearch,
  FiUsers,
  FiFileText,
  FiBarChart2,
  FiAirplay,
  FiCopy,
  FiPaperclip,
} from "react-icons/fi";

export type NavigationState = {
  title: string;
  code?: string;
  url?: string | undefined;
  items: NavigationState[];
  icon?: React.ReactNode;
  badge?: {
    color: string;
    text: string | number;
  };
};

const initialState: NavigationState[] = [
  {
    title: "SIMPIN",
    items: [
      {
        url: "/",
        icon: <FiBarChart2 size={20} />,
        title: "Dashboard",
        items: [],
      },
      {
        url: "/quick-access",
        code: "QA_PERMISSION",
        icon: <FiSearch size={20} />,
        title: "Akses Cepat",
        items: [],
      },
      {
        url: "/member",
        code: "MEMBER_PERMISSION",
        icon: <FiUsers size={20} />,
        title: "Anggota",
        items: [
          {
            url: "/member",
            code: "MEMBER_PERMISSION",
            title: "Anggota Aktif",
            items: [],
          },
          // {
          //   url: "/member/autodebet",
          //   title: "Tarik Data",
          //   items: []
          // },
          {
            url: "/member/history",
            code: "MEMBER_PERMISSION",
            title: "Anggota Nonaktif",
            items: []
          }
        ],
      },
      {
        url: "/",
        icon: <AiFillBank size={20} />,
        title: "Simpanan",
        code: "SAVING_PERMISSION",
        items: [
          {
            url: "/savings",
            code: "SAVING_PERMISSION",
            title: "Daftar",
            items: [],
          },
          {
            url: "/savings/activity",
            code: "SAVING_PERMISSION",
            title: "Aktifitas",
            items: []
          },
        ],
      },
      {
        url: "/",
        icon: <FaHandHolding size={20} />,
        title: "Pinjaman",
        code: "LOAN_PERMISSION",
        items: [
          {
            url: "/loan",
            code: "LOAN_PERMISSION",
            title: "Daftar Pengajuan",
            items: [],
          },
          {
            url: "/loan-active",
            code: "LOAN_PERMISSION",
            title: "Daftar Pinjaman Aktif",
            items: []
          },
          {
            url: "/loan-renewal",
            code: "LOAN_PERMISSION",
            title: "Pembaharuan Pinjaman",
            items: []
          },
          {
            url: "/loan-overdue",
            code: "LOAN_PERMISSION",
            title: "Tunggakan Pinjaman",
            items: []
          },
          {
            url: "/loan-history",
            code: "LOAN_PERMISSION",
            title: "Riwayat Pinjaman",
            items: []
          },
        ]
      },
    ],
  },
  {
    title: "Monitoring",
    code: "PAYMENT_PERMISSION",
    items: [
      {
        url: "/monitor-payment",
        code: "PAYMENT_PERMISSION",
        icon: <FiFileText size={20} />,
        title: "Potong Gaji",
        items: [],
      },
      {
        icon: <FaFileDownload size={20} />,
        url: "/member/autodebet",
        code: "PAYMENT_PERMISSION",
        title: "Tarik Data",
        items: [],
      },
    ]
  },
  {
    title: "User Management",
    code: "USER_MNG_PERMISSION",
    items: [
      {
        url: "/employee",
        code: "EMP_MNG_PERMISSION",
        icon: <FaUsers size={20} />,
        title: "Karyawan",
        items: []
      },
      {
        url: "/user",
        code: "USER_MNG_PERMISSION",
        icon: <FaUserCog size={20} />,
        title: "User",
        items: [],
      },
      {
        url: "/role",
        icon: <FaStar size={20} />,
        title: "Role",
        code: "USER_MNG_PERMISSION",
        items: []
      }
    ],
  },
  {
    title: "Master Data",
    code: "MDATA_PERMISSION",
    items: [
      {
        url: "/master/member-status",
        code: "MDATA_PERMISSION",
        icon: <FaDatabase size={20} />,
        title: "Master Member Status",
        items: []
      },
      {
        url: "/master/loan-scheme",
        code: "MDATA_PERMISSION",
        icon: <FaDatabase size={20} />,
        title: "Master Pinjaman",
        items: []
      },
      {
        url: "/master/saving",
        code: "MDATA_PERMISSION",
        icon: <FaDatabase size={20} />,
        title: "Master Simpanan",
        items: []
      },
      {
        url: "/master/org-position",
        code: "MDATA_PERMISSION",
        icon: <FaDatabase size={20} />,
        title: "Master Posisi Organisasi",
        items: []
      },
      {
        url: "/master/coa",
        code: "MDATA_PERMISSION",
        icon: <FaDatabase size={20} />,
        title: "Master COA",
        items: [
          {
            url: "/master/coa/group",
            code: "MDATA_PERMISSION",
            title: "Group",
            items: [],
          },
          {
            url: "/master/coa/header",
            code: "MDATA_PERMISSION",
            title: "Header",
            items: [],
          },
          {
            url: "/master/coa",
            code: "MDATA_PERMISSION",
            title: "Daftar",
            items: [],
          },
        ]
      },
      {
        url: "/master/factory",
        code: "MDATA_PERMISSION",
        icon: <FaDatabase size={20} />,
        title: "Master Factory",
        items: []
      },
      {
        url: "/master/department",
        code: "MDATA_PERMISSION",
        icon: <FaDatabase size={20} />,
        title: "Master Department",
        items: []
      },
    ],
  },
  {
    title: "Pengaturan",
    code: "APP_SETTING_PERMISSION",
    items: [
      {
        url: "/setting/approval-scheme",
        code: "APP_SETTING_PERMISSION",
        icon: <FaCog size={20} />,
        title: "Skema Persetujuan",
        items: []
      },
    ],
  },
  {
    title: "Stock Management",
    code: "STOCK_MNG_PERMISSION",
    items: [
      {
        url: "/stock-management/product",
        icon: <FaTshirt size={20} />,
        title: "Produk",
        items: [
          {
            url: "/stock-management/product/all",
            code: "STOCK_MNG_PERMISSION",
            title: "Daftar Produk",
            items: [],
          },
          {
            url: "/stock-management/product",
            code: "STOCK_MNG_PERMISSION",
            title: "Daftar Produk Base",
            items: [],
          },
          {
            url: "/stock-management/variant",
            code: "STOCK_MNG_PERMISSION",
            title: "Varian",
            items: [],
          },
          {
            url: "/stock-management/unit",
            code: "STOCK_MNG_PERMISSION",
            title: "Unit",
            items: [],
          },
          {
            url: "/stock-management/category",
            code: "STOCK_MNG_PERMISSION",
            title: "Kategori",
            items: [],
          },
          {
            url: "/stock-management/sub-category",
            code: "STOCK_MNG_PERMISSION",
            title: "Sub-Kategori",
            items: [],
          },
        ],
      },
      {
        url: "/stock-management/supplier",
        code: "STOCK_MNG_PERMISSION",
        icon: <FaTruckPickup size={20} />,
        title: "Supplier",
        items: [],
      },
      {
        url: "/stock-management/pr",
        code: "PR_PERMISSION",
        icon: <FaHandshake size={20} />,
        title: "Purchase Requisition",
        items: [
          {
            url: "/stock-management/pr",
            code: "PR_PERMISSION",
            title: "Pengajuan",
            items: [],
          },
          {
            url: "/stock-management/pr/approval",
            code: "PR_PERMISSION",
            title: "Approval",
            items: [],
          },
        ],
      },
      {
        url: "/stock-management/po",
        code: "PO_PERMISSION",
        icon: <FaExchangeAlt size={20} />,
        title: "Purchase Order",
        items: [
          {
            url: "/stock-management/po",
            code: "PO_PERMISSION",
            title: "Pengajuan",
            items: [],
          },
          {
            url: "/stock-management/po/ordered",
            code: "PO_PERMISSION",
            title: "Dipesan",
            items: [],
          },
          {
            url: "/stock-management/po/received",
            code: "PO_PERMISSION",
            title: "Diterima",
            items: [],
          },
          {
            url: "/stock-management/po/sp",
            code: "PO_PERMISSION",
            title: "SP Approval",
            items: [],
          },
          {
            url: "/stock-management/po/approved",
            code: "PO_PERMISSION",
            title: "Disetujui",
            items: [],
          },
        ],
      },
      {
        url: "/stock-management/retur",
        code: "STOCK_MNG_PERMISSION",
        icon: <FaNewspaper size={20} />,
        title: "Retur",
        items: [
          {
            url: "/stock-management/retur",
            code: "STOCK_MNG_PERMISSION",
            title: "Daftar",
            items: [],
          },
          {
            url: "/stock-management/retur/submitted",
            code: "PO_PERMISSION",
            title: "Diterima",
            items: [],
          },
        ]
      },
      {
        url: "/stock-management/stock",
        code: "STOCK_MNG_PERMISSION",
        icon: <BsFillStopwatchFill size={20} />,
        title: "Stok",
        items: [],
      },
      {
        url: "/stock-management/warehouse",
        code: "STOCK_MNG_PERMISSION",
        icon: <FaWarehouse size={20} />,
        title: "Warehouse",
        items: [
          {
            url: "/stock-management/warehouse",
            code: "STOCK_MNG_PERMISSION",
            title: "Cabang",
            items: [],
          },
          {
            url: "/stock-management/warehouse/print",
            code: "STOCK_MNG_PERMISSION",
            title: "Stock Report",
            items: [],
          },
          {
            url: "/stock-management/warehouse/transfer",
            code: "STOCK_MNG_PERMISSION",
            title: "Transfer",
            items: [
              {
                url: "/stock-management/warehouse/transfer",
                code: "STOCK_MNG_PERMISSION",
                title: "Draft",
                items: [],
              },
              {
                url: "/stock-management/warehouse/transfer/processed",
                code: "STOCK_MNG_PERMISSION",
                title: "Diproses",
                items: [],
              },
              {
                url: "/stock-management/warehouse/transfer/approved",
                code: "STOCK_MNG_PERMISSION",
                title: "Disetujui",
                items: [],
              },
            ],
          },
        ],
      },
      {
        url: "/cafe",
        icon: <FaWineGlass size={20} />,
        code: "CAFE_PERMISSION",
        title: "Cafe",
        items: [
          {
            url: "/cafe/category",
            code: "CAFE_PERMISSION",
            title: "Kategori",
            items: []
          },
          {
            url: "/cafe/menu",
            code: "CAFE_PERMISSION",
            title: "Menu",
            items: []
          }
        ]
      },
      {
        url: "/canteen",
        code: "CANTEEN_PERMISSION",
        icon: <FaStore size={20} />,
        title: "Kantin",
        items: [
          {
            url: "/canteen/menu",
            code: "CANTEEN_PERMISSION",
            title: "Menu",
            items: []
          },
          {
            url: "/canteen/order",
            code: "CANTEEN_PERMISSION",
            title: "Order",
            items: []
          }
        ]
      },
      {
        url: "/photocopy",
        code: "FOTOCOPY_PERMISSION",
        icon: <FiPaperclip size={20} />,
        title: "Photocopy",
        items: [
          {
            url: "/photocopy/machine",
            code: "FOTOCOPY_PERMISSION",
            title: "Mesin",
            items: []
          },
          {
            url: "/photocopy/cycle-count",
            code: "FOTOCOPY_PERMISSION",
            title: "Cycle Count",
            items: []
          },
          {
            url: "/photocopy/order",
            code: "FOTOCOPY_PERMISSION",
            title: "Order",
            items: []
          }
        ]
      },
      {
        url: "/stock-management/usage",
        code: "STOCK_MNG_PERMISSION",
        icon: <FaChartBar size={20} />,
        title: "Usage",
        items: []
      },
    ]
  },
  {
    title: "Invoice",
    code: "STOCK_MNG_PERMISSION",
    items: [
      {
        url: "/stock-management/invoice",
        code: "STOCK_MNG_PERMISSION",
        icon: <BsFillEnvelopePaperFill size={20} />,
        title: "Invoice",
        items: [
          {
            url: "/stock-management/invoice",
            code: "STOCK_MNG_PERMISSION",
            title: "Daftar",
            items: []
          },
          {
            url: "/stock-management/invoice/paid",
            code: "STOCK_MNG_PERMISSION",
            title: "Pembayaran",
            items: []
          },
        ]
      }
    ],
  },
  {
    title: "AP",
    code: "ACCOUNTING_PERMISSION",
    items: [
      {
        url: "/ap",
        code: "ACCOUNTING_PERMISSION",
        icon: <FaCalculator size={20} />,
        title: "Account Payable",
        items: [
          {
            url: "/ap",
            code: "ACCOUNTING_PERMISSION",
            title: "Daftar",
            items: []
          },
          {
            url: "/ap/paid",
            code: "ACCOUNTING_PERMISSION",
            title: "Dibayar",
            items: []
          },
        ]
      }
    ],
  },
  {
    title: "Cash Bank",
    code: "ACCOUNTING_PERMISSION",
    items: [
      {
        url: "/cash-bank",
        code: "ACCOUNTING_PERMISSION",
        icon: <FaPiggyBank size={20} />,
        title: "Cash Bank",
        items: [
          {
            url: "/cash-bank/account",
            code: "ACCOUNTING_PERMISSION",
            title: "Account",
            items: []
          },
          {
            url: "/cash-bank/deposit",
            code: "ACCOUNTING_PERMISSION",
            title: "Deposit",
            items: []
          },
          {
            url: "/cash-bank/transfer",
            code: "ACCOUNTING_PERMISSION",
            title: "Transfer",
            items: []
          },
          {
            url: "/cash-bank/trx",
            code: "ACCOUNTING_PERMISSION",
            title: "Transaksi",
            items: []
          },
        ]
      }
    ],
  },
  {
    title: "Expense",
    code: "ACCOUNTING_PERMISSION",
    items: [
      {
        url: "/expense",
        code: "ACCOUNTING_PERMISSION",
        icon: <FaCcMastercard size={20} />,
        title: "Expense",
        items: [
          {
            url: "/expense",
            code: "ACCOUNTING_PERMISSION",
            title: "Daftar",
            items: []
          },
          {
            url: "/expense/purpose",
            code: "ACCOUNTING_PERMISSION",
            title: "Purpose",
            items: []
          },
        ]
      }
    ],
  },
  {
    title: "Report",
    code: "ACCOUNTING_PERMISSION",
    items: [
      {
        url: "/report/cashflow",
        code: "ACCOUNTING_PERMISSION",
        icon: <FaFileExcel size={20} />,
        title: "Arus Kas",
        items: []
      },
      {
        url: "/report/balance-sheet",
        code: "ACCOUNTING_PERMISSION",
        icon: <FaFileExcel size={20} />,
        title: "Neraca",
        items: []
      },
      {
        url: "/report/profit-loss",
        code: "ACCOUNTING_PERMISSION",
        icon: <FaFileExcel size={20} />,
        title: "Laba Rugi",
        items: []
      },
      {
        url: "/report/shu",
        code: "ACCOUNTING_PERMISSION",
        icon: <FaFileExcel size={20} />,
        title: "SHU",
        items: []
      }
    ],
  },
  {
    title: "Assets Management",
    code: "ACCOUNTING_PERMISSION",
    items: [
      {
        url: "/assets",
        code: "ACCOUNTING_PERMISSION",
        icon: <FaMoneyBillTrendUp size={20} />,
        title: "Assets",
        items: []
      }
    ],
  },
];

// Define the initial state using that type

export const navigationSlice = createSlice({
  name: "navigation",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
});

export default navigationSlice.reducer;
