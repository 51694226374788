import classNames from "classnames";
import Navbar from "layouts/pos/subcomponents/Navbar";
import Head from "next/head";
import { useAppSelector } from "store";
import Toolbar from "./subcomponents/Toolbar";


export const Layout:React.FC<any> = ({children}) => {
  const config = useAppSelector((state) => state.config);

  return (
    <>
      <Head>
        <title>Amsatasik</title>
      </Head>
      <div className={classNames("font-sans antialiased text-sm disable-scrollbars min-h-screen", {"dark": config.background === "dark"})}>
        
        <div className="main w-full bg-gray-50 text-gray-900 dark:bg-gray-900 dark:text-white">
          <Navbar/>
          <div className="w-full px-9 p-4 mx-auto">
            {children}
          </div>
        </div>
        
      </div>
    </>
  );
};

export default Layout;
